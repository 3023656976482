import { graphql, useStaticQuery } from 'gatsby'

const SiteMetadata = () => {
  let data = useStaticQuery(
    graphql`
      {
        site: site {
          siteMetadata {
            title
            phone
            collectionAddress
            dietaryOptions {
              name
              displayName
              price
            }
            earliestCollectionOrDelivery
            latestCollectionOrDelivery
            globalSchema,
          }
        }
      }
    `
  )

  return data.site.siteMetadata
}

export default SiteMetadata
