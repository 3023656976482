import React from 'react'
import { Helmet } from 'react-helmet'
import { MDXProvider } from '@mdx-js/react'
import SiteMetadata from '../components/SiteMetadata'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Cart from '../components/Cart'
import '../styles/_all.sass'

// Set up MDX Shortcodes
import Anchor from './shortcodes/Anchor'
import Button from './shortcodes/Button'
import ContactForm from './shortcodes/ContactForm'
import CTA from './shortcodes/CTA'
import Phone from './shortcodes/Phone'
import Product from './shortcodes/Product'
import Products from './shortcodes/Products'
import FauxTitle from './shortcodes/FauxTitle'

const shortcodes = {
  Anchor,
  Button,
  ContactForm,
  CTA,
  Phone,
  Product,
  Products,
  FauxTitle,
}

/**
 * Construct the global layout
 *
 * @param {string} [className]
 * @param {string} [footerCTAText]
 * @param {string} [footerCTALink]
 * @param {boolean} [footerDimmed=false]
 */
const TemplateWrapper = props => {
  const { title, globalSchema } = SiteMetadata()

  return (
    <div>
      <Helmet>
        <html lang="en-gb" />
        <title>{props.seoTitle || props.title + ' | ' + title}</title>
        <meta name="description" content={props.seoDescription} />
        <script type="application/ld+json">{globalSchema}</script>
        <script type="application/ld+json">{props.seoSchema}</script>
        <meta name="google-site-verification" content="3KMl-wyS_7vTxW6sGmnBu73w8qtEF09S2GSI4AqCYV8" />
      </Helmet>
      <Navbar solid={props.heroImage ? false : true} />
      <div id="main" className={props.className}>
        <MDXProvider components={shortcodes}>{props.children}</MDXProvider>
        <Cart />
      </div>
      <Footer 
        ctaText={props.footerCTAText}
        ctaLink={props.footerCTALink}
        dimmed={props.footerDimmed}
      />
    </div>
  )
}

export default TemplateWrapper
