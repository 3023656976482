import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { add } from 'cart-localstorage'
import SiteMetadata from '../SiteMetadata'
import ProductsStyles from '../../styles/Products.module.sass'

/**
 * Display a section with Products
 *
 * @param {string} type
 * @param {string} class
 */
const Products = props => {
  const data = useStaticQuery(posts)

  const maxQty = 50

  function addToOrderAction(id, title, price, quantity) {
    add({ id: id, name: title, price: price }, quantity)

    // Reload the page to update the floating cart
    // TODO: Research using Redux for this purpose in the future
    window.location.reload()
  }

  // Create product ID maps
  let qtyArray = {}
  let expandedArray = {}

  data.allMdx.edges.map(edge => {
    let id = edge.node.frontmatter.productID

    if (!qtyArray[id]) {
      qtyArray[id] = 1
    }

    if (!expandedArray[id]) {
      expandedArray[id] = false
    }

    return false
  })

  const [qty, setQty] = useState(qtyArray)
  const [expanded, setExpanded] = useState(expandedArray)

  // Increase/Decreate quantity
  function changeQty(operation, id) {
    if (
      (qty[id] <= 1 && operation === 'decrease') ||
      (qty[id] >= maxQty && operation === 'increase')
    ) {
      return false
    }

    let _qty = qty

    if (operation === 'decrease') {
      _qty[id] = ~~_qty[id] - 1
    } else {
      _qty[id] = ~~_qty[id] + 1
    }

    setQty({ ..._qty })
  }

  // Change input quantity
  function inputQty(input, id) {
    let _qty = qty

    if (input === '' || (~~input > 0 && ~~input <= maxQty)) {
      _qty[id] = ~~input
    } else if (input < 1) {
      _qty[id] = 1
    } else if (input > maxQty) {
      _qty[id] = maxQty
    }

    setQty({ ..._qty })
  }

  // Change expanded state
  function changeExpanded(id) {
    let _expanded = expanded

    _expanded[id] = !_expanded[id]

    setExpanded({ ..._expanded })
  }

  // Define states for the Dietary Options section
  const {dietaryOptions} = SiteMetadata()

  // Restructure dietary Options object for better usability
  const restructuredDietaryOptions = {}

  for (const i in dietaryOptions) {
    restructuredDietaryOptions[dietaryOptions[i].name] = {
      displayName: dietaryOptions[i].displayName,
      price: dietaryOptions[i].price
    }
  }

  const defaultQuartersState = [dietaryOptions[0].name, dietaryOptions[0].name, dietaryOptions[0].name, dietaryOptions[0].name]
  const defaultProductDataState = {id: null, name: null, price: null}

  const [dietaryBaseProduct, setDietaryBaseProduct] = useState({ open: false, ...defaultProductDataState })
  const [dietaryQuarters, setDietaryQuarters] = useState(defaultQuartersState)
  const [dietaryProductData, setDietaryProductData] = useState(defaultProductDataState)

  useEffect(() => {
    const baseId = dietaryBaseProduct.id
    const baseName = dietaryBaseProduct.name
    const basePrice = dietaryBaseProduct.price
    let idAddition = ''
    let nameAddition = ''
    let priceAddition = 0

    const usableQuarters = {} // {name: quantity}

    // Generate usableQuarters
    for (const i in dietaryQuarters) {
      // If object key exists
      if (usableQuarters[dietaryQuarters[i]]) {
        // Increment quantity
        usableQuarters[dietaryQuarters[i]]++
      }
      else {
        // Initialise key
        usableQuarters[dietaryQuarters[i]] = 1
      }
    }

    // Generate dietary product data
    for (const item in usableQuarters) {
      const name = item
      const quantity = usableQuarters[item]

      // Ignore default dietary option
      if (name !== dietaryOptions[0].name) {
        if (quantity === 1) {
          nameAddition += '1 Quarter ' +
            restructuredDietaryOptions[name].displayName +
            (restructuredDietaryOptions[name].price > 0 ? ' - \u20ac' + restructuredDietaryOptions[name].price + ' extra' : '') +
            ', ' // ex: 1 Quarter Vegan - €5 extra, ...

          priceAddition += Number(restructuredDietaryOptions[name].price)
        }
        else if (quantity === 2) {
          nameAddition += 'Half ' +
            restructuredDietaryOptions[name].displayName +
            (restructuredDietaryOptions[name].price > 0 ? ' - \u20ac' + (Number(restructuredDietaryOptions[name].price) * 2) + ' extra' : '') +
            ', ' // ex: Half Vegan - €10 extra, ...

          priceAddition += Number(restructuredDietaryOptions[name].price) * 2
        }
        else if (quantity === 3) {
          nameAddition += '3 Quarters ' +
            restructuredDietaryOptions[name].displayName +
            (restructuredDietaryOptions[name].price > 0 ? ' - \u20ac' + (Number(restructuredDietaryOptions[name].price) * 3) + ' extra' : '') +
            ', ' // ex: 3 Quarters Vegan - €15 extra, ...

          priceAddition += Number(restructuredDietaryOptions[name].price) * 3
        }
        else if (quantity === 4) {
          nameAddition += 'FULL ' +
            restructuredDietaryOptions[name].displayName +
            (restructuredDietaryOptions[name].price > 0 ? ' - \u20ac' + (Number(restructuredDietaryOptions[name].price) * 4) + ' extra' : '') +
            ', ' // ex: FULL Vegan - €20 extra, ...

          priceAddition += Number(restructuredDietaryOptions[name].price) * 4
        }

        idAddition += '-' + quantity + 'q-' + name
      }
    }

    // Clean up nameAddition
    if (nameAddition.length > 2) {
      nameAddition = nameAddition.slice(0, -2)
    }

    // Set new dietaryProductData
    setDietaryProductData({
      id: idAddition.length ? baseId + idAddition : baseId,
      name: nameAddition.length ? baseName + ' (' + nameAddition + ')' : baseName,
      price: priceAddition > 0 ? Number(basePrice) + Number(priceAddition) : basePrice
    })
  }, [dietaryQuarters, dietaryBaseProduct])

  function triggerDietaryOptions(id, name, price) {
    // Open Dietary Options panel
    setDietaryBaseProduct({
      open: true,
      id: id,
      name: name,
      price: price
    })
  }

  function resetDietaryOptions() {
    // Close Dietary Options panel
    setDietaryBaseProduct({ open: false, ...defaultProductDataState })

    // Reset Quarters
    setDietaryQuarters(defaultQuartersState)

    // Clear dietary product data
    setDietaryProductData(defaultProductDataState)
  }

  function updateDietaryQuarters(i, value) {
    const _dietaryQuarters = [...dietaryQuarters]
    _dietaryQuarters[i] = value

    setDietaryQuarters([..._dietaryQuarters])
  }

  return (
    <div className={`container is-wider-than-parent has-text-left ${props.className || ''} ${ProductsStyles.products || ''}`}>
      <div className="columns is-multiline is-centered">
        {data.allMdx.edges.map(edge => {
          return (
            <div
              key={edge.node.id}
              className={`column is-one-third-tablet ${ProductsStyles.product || ''}  ${expanded[edge.node.frontmatter.productID] ? ProductsStyles.expanded : ''}`}
            >
              {edge.node.fields.image ? (
                <Img
                  className={ProductsStyles.image}
                  fluid={edge.node.fields.image.childImageSharp.fluid}
                />
              ) : null}
              <div className={ProductsStyles.vitals}>
                <div className={ProductsStyles.titles}>
                  <strong className={ProductsStyles.title}>
                    {edge.node.frontmatter.title}
                  </strong>
                  <em className={ProductsStyles.productType}>
                    {edge.node.frontmatter.forcedTypeName || props.type || edge.node.frontmatter.productType}
                  </em>
                  <em className={`is-hidden ${ProductsStyles.peopleFed}`}>
                    For {edge.node.frontmatter.peopleFed} guests
                  </em>
                </div>
                <span className={ProductsStyles.price}>
                  <i>&euro;</i>
                  {edge.node.frontmatter.price}
                </span>
                <span className={ProductsStyles.pricePerPerson}>
                  {edge.node.frontmatter.pricePerPerson} per person
                </span>
              </div>
              <button
                className={`button is-hidden-desktop ${ProductsStyles.expand ||
                  ''}`}
                onClick={() => changeExpanded( edge.node.frontmatter.productID )}
              >
                Info &amp; Order
              </button>
              <div
                className={`is-hidden-touch ${ProductsStyles.nonVitals || ''}`}
              >
                <div className={ProductsStyles.description || ''}
                  dangerouslySetInnerHTML={{ __html: edge.node.frontmatter.description }}
                />
                <div className={ProductsStyles.addToOrder}>
                  <div className={ProductsStyles.quantityWidget}>
                    <input
                      type="text"
                      name="quantity"
                      value={qty[edge.node.frontmatter.productID]}
                      onChange={e => {
                        inputQty(
                          e.target.value,
                          edge.node.frontmatter.productID
                        )
                      }}
                    />
                    <span
                      className={`tag ${ProductsStyles.increase || ''}`}
                      onClick={() => {
                        changeQty('increase', edge.node.frontmatter.productID)
                      }}
                      onKeyDown={() => {
                        changeQty('increase', edge.node.frontmatter.productID)
                      }}
                      role="button"
                      tabIndex="0"
                    >
                      +
                    </span>
                    <span
                      className={`tag ${ProductsStyles.decrease || ''}`}
                      onClick={() => {
                        changeQty('decrease', edge.node.frontmatter.productID)
                      }}
                      onKeyDown={() => {
                        changeQty('decrease', edge.node.frontmatter.productID)
                      }}
                      role="button"
                      tabIndex="0"
                    >
                      –
                    </span>
                  </div>
                  <button
                    className={`button ${ProductsStyles.addToOrderButton ||
                      ''}`}
                    onClick={() => {
                      addToOrderAction(
                        edge.node.frontmatter.productID,
                        edge.node.frontmatter.title +
                          ' ' +
                          edge.node.frontmatter.productType,
                        edge.node.frontmatter.price,
                        qty[edge.node.frontmatter.productID]
                      )
                    }}
                  >
                    Add to Order
                  </button>
                </div>
              </div>
              {/* {edge.node.frontmatter.dietaryOptionsAllowed && (
                  <button 
                    className={`button is-ghost ${ProductsStyles.dietaryOptionsButton || ''}`}
                    onClick={() => {
                      triggerDietaryOptions(
                        edge.node.frontmatter.productID,
                        edge.node.frontmatter.title +
                        ' ' +
                        edge.node.frontmatter.productType,
                        edge.node.frontmatter.price
                      )
                    }}
                  >
                    Dietary Options
                  </button>
                )
              } */}
            </div>
          )
        })}
      </div>

      {
        /* Only show the modal if it's active */
        dietaryBaseProduct.open &&
        <div
          className={`modal is-active ${ProductsStyles.dietaryOptionsModal || ''}`}
        >
          <div
            className='modal-background'
            onClick={() => { resetDietaryOptions() }}
          >
            {/* Empty */}
          </div>

          <div className={`modal-content box ${ProductsStyles.dietaryOptionsBox || ''}`}>
            <div className={`box-header is-primary ${ProductsStyles.boxHeader || ''}`}>
              <strong>Dietary Options</strong>
              <i>{dietaryBaseProduct.name}</i>
            </div>

            <div className={`box-content ${ProductsStyles.boxContent || ''}`}>
              {
                // Create 4 boxes (for 4 quarters)
                [...Array(4)].map((e, i) => {
                  const indexPlus1 = i + 1

                  return (
                    <div className="field" key={'quarter-' + i}>
                      <label className="label">
                        Quarter {indexPlus1}
                      </label>
                      <div className="control">
                        <div className="select">
                          <select // eslint-disable-line jsx-a11y/no-onchange
                            defaultValue={dietaryOptions[0].name}
                            onChange={event => {
                              console.log(i, event.target.value)
                              updateDietaryQuarters(i, event.target.value)
                            }}
                          >
                            { dietaryOptions.map((e) => {
                              return (
                                <option key={e.name} value={e.name}>{e.displayName}{e.price > 0 && ' +\u20ac' + e.price}</option>
                              )
                            }) }
                          </select>
                        </div>
                      </div>
                    </div>
                  )
                })
              }

              <div className={`is-hidden-mobile ${ProductsStyles.chart || ''}`}>
                {
                  // Create 4 pie chart quarters)
                  [...Array(4)].map((e, i) => {
                    return (
                      <div className={ProductsStyles.slice} data-type={dietaryQuarters[i]} key={i + dietaryQuarters[i]}>
                        <span>{restructuredDietaryOptions[dietaryQuarters[i]]['displayName']}</span>
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <div className={`box-footer ${ProductsStyles.boxFooter || ''}`}>
              <span className={ProductsStyles.boxPrice || ''}>
                <i>&euro;</i>
                {dietaryProductData.price}
              </span>

              <button
                className={`button is-link ${ProductsStyles.boxAddToOrderButton || ''}`}
                onClick={() => {
                  addToOrderAction(
                    dietaryProductData.id,
                    dietaryProductData.name,
                    dietaryProductData.price,
                    1
                  )
                }}
              >
                Add to Order
              </button>
            </div>

            {/* Modal Close Button */}
            <button
              className={`delete is-large ${ProductsStyles.close || ''}`} aria-label="close"
              onClick={() => { resetDietaryOptions() }}
            >
              {/* Empty */}
            </button>
          </div>
        </div>
      }
    </div>
  )
}

export default Products

export const posts = graphql`
  {
    allMdx(filter: {frontmatter: {templateKey: {eq: "product"}}}, sort: {fields: frontmatter___productID, order: ASC}) {
      edges {
        node {
          id
          fields {
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          frontmatter {
            description
            dietaryOptionsAllowed
            title
            price
            productType
            forcedTypeName
            peopleFed
            pricePerPerson
            productID
          }
        }
      }
    }
  }
`
