import React from 'react'
import CTA from './shortcodes/CTA'
import SiteMetadata from './SiteMetadata'
import FooterStyles from '../styles/Footer.module.sass'

/**
 * Display the global footer
 * 
 * @param {string} [ctaText]
 * @param {string} [ctaLink]
 * @param {boolean} [dimmed=false] - Reduce footer opacity
 */
const Footer = props => {
  const { title } = SiteMetadata()

  return (
    <footer className={`footer ${FooterStyles.footer || ''} ${props.dimmed === true ? FooterStyles.dimmed : ''}`}>
      <CTA 
        text={props.ctaText || "Place an Order Now" }
        link={props.ctaLink || "/"}
        class={FooterStyles.cta} 
      />

      <div className="container has-text-centered is-semi-narrow">
        <p>
          Copyright{' '}
          <span className="current-year">{new Date().getFullYear()}</span>{' '}
          {title}. All rights reserved
          <span className={FooterStyles.shamrock}>
            From Dublin with rich flavours
          </span>
        </p>
      </div>
    </footer>
  )
}

export default Footer
