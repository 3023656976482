import React from 'react'
import { Link } from 'gatsby'
import CTAStyles from '../../styles/CTA.module.sass'

/**
 * Display a call-to-action section
 *
 * @param {string} text Button label
 * @param {string} link Button link
 * @param {string} before Text above button
 * @param {string} after Text after button
 * @param {string} class Element class
 */
const CTA = props => {
  return (
    <div className={`cta ${CTAStyles.cta} ${props.class || ''}`}>
      {props.before ? <em>{props.before}</em> : null}
      <Link
        to={props.link || '/contact/'}
        className={`button is-link is-large ${CTAStyles.button || ''}`}
      >
        {props.text || 'Get a Quote'}
      </Link>
      {props.after ? <em>{props.after}</em> : null}
    </div>
  )
}

export default CTA
